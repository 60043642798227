<template>

  <v-container grid-list-md>
    
    <h2 class="pa-2">サンプル一覧</h2>
    <v-divider></v-divider>

    <v-row class="mt-4" justify="center" align="center">
      <v-col lg="10">
        <div class="text-right">
          <v-btn dark color="green"  :to="'/samples/add'"
            >追加</v-btn
          >
        </div>
      </v-col>
    </v-row>

    <v-row justify="center" align="center">
      <v-col lg="10">
        <v-card color="#E0F2F1">
          <v-card-title class="py-2 px-4 indigo">
            <h3 class="subtitle-1 font-weight-bold white--text">
              検索
            </h3>
          </v-card-title>
          <v-divider> </v-divider>
          <form id="search_form">
          <v-row class="pa-6 pb-0">
            <v-col cols="12" md="6">
              <v-text-field
                label="名前"
                v-model="searchForm.name"
                outlined
                hide-details
                color="indigo"
                background-color="white"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                label="メールアドレス"
                v-model="searchForm.email"
                outlined
                hide-details
                color="indigo"
                background-color="white"
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4">
              <v-select
                label="ステータス"
                v-model="searchForm.status"
                :items="[{ label: '全て', id: '' }, ...statuses]"
                item-text="label"
                item-value="id"
                outlined
                hide-details
                flat
                color="cyan darken-3"
                background-color="white"
                dense
              ></v-select>
            </v-col>
            <v-col cols="12" md="4">
              <v-select
                label="タイプ"
                v-model="searchForm.type"
                :items="[{ label: '全て', id: '' }, ...types]"
                item-text="label"
                item-value="id"
                outlined
                hide-details
                flat
                color="cyan darken-3"
                background-color="white"
                dense
              ></v-select>
            </v-col>
            <v-col cols="12" md="4">
              <v-checkbox
                label="アクティブ"
                color="indigo darken-2"
                value="1"
                hide-details
                class="pt-0 mt-2"
                v-model="searchForm.is_active"
              ></v-checkbox>
            </v-col>
          </v-row>
          </form>
          <div class="text-center py-4">
            <v-btn class="ma-2" dark color="indigo" @click="sampleSearch()">
              <v-icon>mdi-magnify</v-icon>
              検索
            </v-btn>
            <v-btn
              class="ma-2"
              outlined
              color="indigo"
                v-bind:href="'/samples?clear'"
            >
              クリア
            </v-btn>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <v-row justify="center" align="center" class="mt-4 mb-5">
      <v-col lg="10">
        <v-card>
          <v-data-table
            :headers="headers"
            :items="items"
            hide-default-footer
            no-data-text="データがありません"
            :custom-sort="tableSort"
            :loading="isLoading"
            loading-text="ロード中"
            disable-pagination
          >
            <div class="text-center pt-2"></div>
            <template v-slot:item="{ item }">
              <tr>
                <td>
                  <v-btn
                    color="green"
                    small
                    dark
                    :to="'/samples/edit/' + item.id"
                    >編集</v-btn
                  >
                </td>
                <td>{{ item.name }}</td>
                <td v-if="item.is_active">
                  〇
                </td>
                <td v-else></td>
                <td v-if="statusesFlat[item.status]">
                  {{ statusesFlat[item.status] }}
                </td>
                <td v-else></td>
                <td v-if="typesFlat[item.type]">
                  {{ typesFlat[item.type] }}
                </td>
                <td v-else></td>
                <td>{{ item.memo }}</td>
                <td>
                  <v-btn
                    small
                    dark
                    color="deep-orange accent-4"
                    @click="deleteitem(item.id)"
                    :disabled="buttonStatus"
                    >削除</v-btn
                  >
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
        <div class="text-center py-5">
          <v-pagination
            v-model="page"
            :length="pageCount"
            @input="emitChangePage()"
            color="indigo"
          ></v-pagination>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Search from "../../mixins/Search.js";

export default {
  mixins: [Search],
  data() {
    return {
      samples: {},
      statuses: [],
      types: [],
      typesFlat: {},
      statusesFlat: {},
      buttonStatus: false,
      //検索条件
      searchForm: {
        //検索のデフォルト値
        name: "",
        is_active: "",
        status: "",
        type: "",
        direction: "",
        sort: "",
      },
      //search用
      searchScheme: [
        "name",
        "is_active",
        "status",
        "type",
        "direction",
        "sort",
      ],
      searchURL: "/api/samples",
      page: 1,
      sortable: [
        {
          text: "氏名",
          value: "name",
        },
        {
          text: "アクティブ",
          value: "is_active",
        },
        {
          text: "ステータス",
          value: "status",
        },
        {
          text: "タイプ",
          value: "type",
        },
        {
          text: "メモ",
          value: "memo",
        },
      ],
      headers: [
        {
          text: "編集",
          sortable: false,
          value: "date",
          width: "8%",
        },
        {
          text: "氏名",
          value: "name",
          sortable: true,
          width: "20%",
        },
        {
          text: "アクティブ",
          sortable: true,
          width: "18%",
        },
        {
          text: "ステータス",
          value: "status",
          sortable: true,
          width: "20%",
        },
        {
          text: "タイプ",
          value: "type",
          sortable: true,
          width: "20%",
        },
        {
          text: "メモ",
          value: "memo",
          sortable: true,
          width: "30%",
        },
        {
          text: "削除",
          sortable: false,
          value: "delete",
          width: "8%",
        },
      ],
    };
  },
  mounted() {
    (async () => {
      //先にマスターデータを取得しておく。
      await this.$axios.get("/api/masters/statuses").then((response) => {
        this.statuses = response.data;
        for (const item of response.data) {
          this.statusesFlat[item.id] = item.label;
        }
      });

      //先にマスターデータを取得しておく。
      await this.$axios.get("/api/masters/types").then((response) => {
        this.types = response.data;
        for (const item of response.data) {
          this.typesFlat[item.id] = item.label;
        }
      });
      this.list(false);
    })();
  },
  methods: {
    list(scrollFlg) {
      let query = this.$route.query;
      query.scrollFlg = scrollFlg;
      query.direction = "asc";
      query.sort = "name";
      this.search(query);
    },
    emitChangePage() {
      let value = {
        page: this.dataPage,
        pageCount: this.pageCount,
        itemsPerPage: this.itemsPerPage,
      };
      this.changePage(value);
    },

    //検索ボタンがクリックされた時
    sampleSearch() {
      let value = {};
      for (const key of this.searchScheme) {
        value[key] = this.searchForm[key];
      }
      this.searchForm = value;
      let query = this.getSearchBase();
      this.search(query);
    },
    deleteitem(id) {
      if (confirm("削除してもよろしいですか？")) {
        this.buttonStatus = true;
        this.$axios.delete(`/api/samples/${id}`).then((response) => {
          if (response.data.result == true) {
            this.buttonStatus = false;
            this.list();
            this.$store.commit("setMessege", {
              text: response.data.message,
              color: "success",
            });
          }
        });
      }
    },
  },
};
</script>
